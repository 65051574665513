import React from "react";
import { ButtonEnum } from "../../beans";
import { globe } from "../../constants/icons";
import Button from "../Button";
import Icon from "../Icon";
import { useTranslation } from "react-i18next";
import { Wrap } from "./styles";
import MenuPopover from "../MenuPopover";

const LangSwitcher = () => {
	const { t, i18n } = useTranslation();

	const changeLanguageHandler = (lang: string) => {
		i18n.changeLanguage(lang);
	};

	return (
		<Wrap>
			<div id="lang">
				<MenuPopover
					hoverButton={
						<Button style={ButtonEnum.icon}>
							<Icon icon={globe} />{" "}
							<span className="lang">{i18n.resolvedLanguage}</span>
						</Button>
					}
					menuTitle={t("lang.language")}
				>
					<Button
						style={ButtonEnum.menu}
						onClick={() => changeLanguageHandler("en")}
					>
						English
					</Button>
					<Button
						style={ButtonEnum.menu}
						onClick={() => changeLanguageHandler("es")}
					>
						Español
					</Button>
				</MenuPopover>
			</div>
		</Wrap>
	);
};

export default LangSwitcher;
