import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getAuthHeader } from "../../api/auth";
import { UserDesign } from "../../beans";
import { apiUrl } from "../../constants/endpoints";
import { call, put, StrictEffect, takeEvery } from "redux-saga/effects";

export interface UserDesignState {
	data: UserDesign | null;
	error: null | string;
	loading: boolean;
	loaded: boolean;
}

const initialState: UserDesignState = {
	data: null,
	error: null,
	loading: false,
	loaded: false,
};

function* setUserDesignSaga(
	action: PayloadAction<number>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<StrictEffect, void, any> {
	try {
		const headers = getAuthHeader();
		const response = yield call(fetch, `${apiUrl}/designs/item/select/`, {
			method: "POST",
			body: JSON.stringify({ item_id: action.payload }),
			headers: headers,
			credentials: "include",
		});
		const data = yield response.json();
		if (response.status === 200 || response.status === 201) {
			yield put(setUserDesignSuccess(data));
		} else {
			yield put(setUserDesignFailure(data));
		}
	} catch (error: unknown) {
		console.error("ERROR", error);
	}
}

function* patchUserDesignSaga(
	action: PayloadAction<{ designId: string; payload: object }>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<StrictEffect, void, any> {
	try {
		const headers = getAuthHeader();
		const { designId, payload } = action.payload;
		const response = yield call(
			fetch,
			`${apiUrl}/designs/userdesign/${designId}/`,
			{
				method: "PATCH",
				body: JSON.stringify(payload),
				headers: headers,
				credentials: "include",
			}
		);
		const data = yield response.json();
		if (response.status === 200 || response.status === 201) {
			yield put(patchUserDesignSuccess(data));
		} else {
			yield put(patchUserDesignFailure(data));
		}
	} catch (error: unknown) {
		console.error("ERROR", error);
	}
}

function* getUserDesignSaga(
	action: PayloadAction<number>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<StrictEffect, void, any> {
	try {
		const headers = getAuthHeader();
		const designId = action.payload;
		const response = yield call(
			fetch,
			`${apiUrl}/designs/userdesign/${designId}/`,
			{
				headers: headers,
				credentials: "include",
			}
		);
		const data = yield response.json();
		if (response.status === 200 || response.status === 201) {
			yield put(getUserDesignSuccess(data));
		} else {
			yield put(getUserDesignFailure(data));
		}
	} catch (error: unknown) {
		console.error("ERROR", error);
	}
}

function* patchUserDesignPrintSaga(
	action: PayloadAction<{ designId: string; printId: string; payload: object }>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<StrictEffect, void, any> {
	try {
		const headers = getAuthHeader();
		const { designId, printId, payload } = action.payload;
		const response = yield call(
			fetch,
			`${apiUrl}/designs/userdesigns/${designId}/print/${printId}/`,
			{
				method: "PATCH",
				body: JSON.stringify(payload),
				headers: headers,
				credentials: "include",
			}
		);
		const data = yield response.json();
		if (response.status === 200 || response.status === 201) {
			yield put(patchUserDesignPrintSuccess(data));
		} else {
			yield put(patchUserDesignPrintFailure(data));
		}
	} catch (error: unknown) {
		console.error("ERROR", error);
	}
}

export const createUserDesignLabelThunk = createAsyncThunk(
	"user_design/label/create",
	async (patchRequest: { designId: string; payload: object }) => {
		const headers = getAuthHeader();
		const { designId, payload } = patchRequest;
		const response = await fetch(
			`${apiUrl}/designs/userdesigns/${designId}/label/`,
			{
				method: "POST",
				body: JSON.stringify(payload),
				headers: headers,
				credentials: "include",
			}
		);
		if (!response.ok) {
			throw new Error(`Response status: ${response.status}`);
		}

		return await response.json();
	}
);

export const patchUserDesignLabelThunk = createAsyncThunk(
	"user_design/label/patch",
	async (patchRequest: {
		designId: string;
		labelId: string;
		payload: object;
	}) => {
		const headers = getAuthHeader();
		const { designId, labelId, payload } = patchRequest;
		const response = await fetch(
			`${apiUrl}/designs/userdesigns/${designId}/label/${labelId}/`,
			{
				method: "PATCH",
				body: JSON.stringify(payload),
				headers: headers,
				credentials: "include",
			}
		);
		if (!response.ok) {
			throw new Error(`Response status: ${response.status}`);
		}

		return await response.json();
	}
);

export const deleteUserDesignLabelThunk = createAsyncThunk(
	"user_design/label/delete",
	async (patchRequest: { designId: string; labelId: string }) => {
		const headers = getAuthHeader();
		const { designId, labelId } = patchRequest;
		const response = await fetch(
			`${apiUrl}/designs/userdesigns/${designId}/label/${labelId}/`,
			{
				method: "DELETE",
				headers: headers,
				credentials: "include",
			}
		);
		if (!response.ok) {
			throw new Error(`Response status: ${response.status}`);
		}
		return null;
	}
);

export const userDesignSlice = createSlice({
	name: "userDesign",
	initialState,

	reducers: {
		resetUserDesign: () => initialState,
		update: (state, action: PayloadAction<UserDesign>) => {
			state.data = action.payload;
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		setUserDesignRequest: (state, _action: PayloadAction<number>) => {
			state.loading = true;
			state.loaded = false;
		},
		setUserDesignSuccess: (state, action: PayloadAction<UserDesign>) => {
			state.data = action.payload;
			state.loading = false;
			state.loaded = true;
		},
		setUserDesignFailure: (state, action: PayloadAction<string>) => {
			state.error = action.payload ?? "Unknown error";
			state.loading = false;
			state.loaded = true;
		},

		patchUserDesignRequest: (
			state,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			_action: PayloadAction<{ designId: string; payload: object }>
		) => {
			state.loading = true;
			state.loaded = false;
		},
		patchUserDesignSuccess: (state, action: PayloadAction<UserDesign>) => {
			state.data = action.payload;
			state.loading = false;
			state.loaded = true;
		},
		patchUserDesignFailure: (state, action: PayloadAction<string>) => {
			state.error = action.payload ?? "Unknown error";
			state.loading = false;
			state.loaded = true;
		},

		getUserDesignRequest: (
			state,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			_action: PayloadAction<number>
		) => {
			state.loading = true;
			state.loaded = false;
		},
		getUserDesignSuccess: (state, action: PayloadAction<UserDesign>) => {
			state.data = action.payload;
			state.loading = false;
			state.loaded = true;
		},
		getUserDesignFailure: (state, action: PayloadAction<string>) => {
			state.error = action.payload ?? "Unknown error";
			state.loading = false;
			state.loaded = true;
		},
		patchUserDesignPrintRequest: (
			state,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			_action: PayloadAction<{
				designId: string;
				printId: string;
				payload: object;
			}>
		) => {
			state.loading = true;
			state.loaded = false;
		},
		patchUserDesignPrintSuccess: (state, action: PayloadAction<UserDesign>) => {
			state.data = action.payload;
			state.loading = false;
			state.loaded = true;
		},
		patchUserDesignPrintFailure: (state, action: PayloadAction<string>) => {
			state.error = action.payload ?? "Unknown error";
			state.loading = false;
			state.loaded = true;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(createUserDesignLabelThunk.pending, (state) => {
			state.loading = true;
			state.loaded = false;
		});
		builder.addCase(createUserDesignLabelThunk.fulfilled, (state, action) => {
			if (state.data) state.data.label = action.payload;
			state.loading = false;
			state.loaded = true;
		});
		builder.addCase(createUserDesignLabelThunk.rejected, (state, action) => {
			state.error = action.error.message ?? "Unknown error";
			state.loading = false;
			state.loaded = true;
		});

		builder.addCase(patchUserDesignLabelThunk.pending, (state) => {
			state.loading = true;
			state.loaded = false;
		});
		builder.addCase(patchUserDesignLabelThunk.fulfilled, (state, action) => {
			if (state.data) state.data.label = action.payload;
			state.loading = false;
			state.loaded = true;
		});
		builder.addCase(patchUserDesignLabelThunk.rejected, (state, action) => {
			state.error = action.error.message ?? "Unknown error";
			state.loading = false;
			state.loaded = true;
		});

		builder.addCase(deleteUserDesignLabelThunk.pending, (state) => {
			state.loading = true;
			state.loaded = false;
		});
		builder.addCase(deleteUserDesignLabelThunk.fulfilled, (state) => {
			if (state.data) state.data.label = null;
			state.loading = false;
			state.loaded = true;
		});
		builder.addCase(deleteUserDesignLabelThunk.rejected, (state, action) => {
			state.error = action.error.message ?? "Unknown error";
			state.loading = false;
			state.loaded = true;
		});
	},
});

export const {
	update,
	resetUserDesign,
	setUserDesignRequest,
	setUserDesignFailure,
	setUserDesignSuccess,
	patchUserDesignRequest,
	patchUserDesignFailure,
	patchUserDesignSuccess,
	getUserDesignRequest,
	getUserDesignFailure,
	getUserDesignSuccess,
	patchUserDesignPrintRequest,
	patchUserDesignPrintFailure,
	patchUserDesignPrintSuccess,
} = userDesignSlice.actions;

export default userDesignSlice.reducer;

export function* sagas() {
	yield takeEvery(setUserDesignRequest, setUserDesignSaga);
	yield takeEvery(patchUserDesignRequest, patchUserDesignSaga);
	yield takeEvery(getUserDesignRequest, getUserDesignSaga);
	yield takeEvery(patchUserDesignPrintRequest, patchUserDesignPrintSaga);
}
