import React from "react";
import {
	ArtworkDetail,
	ButtonEnum,
	Production,
	ProductionSize,
	ProductionTimeEnum,
	SwatchEnum,
	UserDesign,
} from "../../beans";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { Wrap } from "./styles";

import ArtworkPreview from "../ArtworkPreview";
import { translateSize } from "../../utils/translate";
import Divider from "../Divider";

import { useTranslation } from "react-i18next";
import Button from "../Button";
import { showModal } from "../../store/UI";

import Fabric from "../Fabric";
import LoadingOptions from "../LoadingOptions";
import Swatch from "../Swatch";

type Props = {
	edit?: boolean;
	userDesign: UserDesign;
	production?: Production;
};
const ProductionDesigns = (props: Props) => {
	const { userDesign, production, edit } = props;

	const dispatch = useDispatch<AppDispatch>();
	const { t } = useTranslation();

	const getTotal = (data: Record<string, number>) => {
		let sum = 0;
		if (data) {
			Object.keys(data).forEach((key) => {
				sum += Number(data[key as keyof object]);
			});

			return sum;
		}
	};

	const handleChangeColorFabric = (userDesign: UserDesign) => {
		dispatch(
			showModal({
				title: "Change color and fabric",
				content: <Fabric design={userDesign} />,
			})
		);
	};

	return userDesign ? (
		<Wrap>
			<div className="production-designs-container">
				{userDesign && userDesign.print_set?.length > 0 ? (
					userDesign.print_set.map((print: ArtworkDetail, k: number) => (
						<div className="print-preview" key={k}>
							<ArtworkPreview
								artwork={print}
								design={userDesign}
								size="small"
							/>
						</div>
					))
				) : (
					<div className="print-preview" key="item-preview">
						<ArtworkPreview design={userDesign} size="small" />
					</div>
				)}
				<div className="production-designs-actions">
					<div className="title title-sm">
						{" "}
						{userDesign?.item_fabric?.item?.name}
					</div>
					{edit && (
						<div className="actions">
							<div className="sizes-container">
								{t("fabric.color")}:
								<>
									<Swatch
										color={userDesign.item_fabric.col_fabric.color}
										key={userDesign.item_fabric.col_fabric.id}
										size={SwatchEnum.small}
									/>
									{userDesign.item_fabric.col_fabric.color.name}

									<Button
										style={ButtonEnum.link}
										onClick={() => handleChangeColorFabric(userDesign)}
									>
										{t("button.change")}
									</Button>
								</>
							</div>

							{production?.sizes && (
								<div className="sizes-container">
									{Object.keys(production.sizes).map(function (
										key: string,
										i: number
									) {
										return (
											production &&
											production.sizes &&
											(production.sizes?.[key as keyof ProductionSize] ?? 0) >
												0 && (
												<div className="size-row" key={`${key}_${i}`}>
													<div className="label-sm size">
														{translateSize(key)}
													</div>
													<div className="body-sm">
														{production.sizes[key as keyof ProductionSize]}
													</div>
												</div>
											)
										);
									})}
									<Divider vertical />

									<div className="size-row" key="total">
										<div className="label-sm size">
											{" "}
											{t("productionSummary.totalUnits")}
										</div>
										<div className="body-sm total">
											{getTotal(production.sizes)}
										</div>
									</div>
								</div>
							)}
						</div>
					)}
					<div className="sizes-container">
						{t("productionSummary.productionTime")}:{" "}
						{production?.sample
							? ProductionTimeEnum.sample
							: ProductionTimeEnum.production}{" "}
						{t("vocabulary.weeks")}
					</div>
				</div>
			</div>
		</Wrap>
	) : (
		<LoadingOptions />
	);
};

export default ProductionDesigns;
