import styled from "styled-components";
import { breakpoints } from "../../constants/breakpoints";

export const Wrap = styled.div`
	display: inline-flex;
	width: 100%;
	height: 100%;
	min-height: 300px;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;

	.product-image-container {
		cursor: pointer;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		position: relative;
		border-radius: 4px;

		img {
			object-fit: contain;
			max-width: 100%;
			max-height: 100%;
			display: none;
			&.loaded {
				display: block;
			}
		}
		button {
			position: absolute;
			display: none;
			bottom: 20px;
			z-index: 3;
		}
	}
	.product-label {
		color: var(--gray130);
	}
	&:hover {
		.product-image-container {
			button {
				display: block;
			}
		}
	}
	@media only screen and (max-width: ${breakpoints.mobile}px) {
		&:hover {
			.product-image-container {
				button {
					display: none;
				}
			}
		}
	}
`;
