import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { selectProductionRange } from "../../store/ProductionRange";

import {
	ActionCardEnum,
	AlertEnum,
	ButtonEnum,
	OrderItem as OrderItemBean,
	ProductionRange,
} from "../../beans";
import SizeSelector from "../../components/SizeSelector";
import Card from "../../components/Card";
import ActionCard from "../../components/ActionCard";
import ProductionDesigns from "../../components/ProductionDesigns";

import SampleSizeSelector from "../../components/SampleSizeSelector";
import SubsectionTitle from "../../components/SubsectionTitle";

import { goToEditDesign } from "../../constants/routes";
import LoadingOptions from "../../components/LoadingOptions";
import Alert from "../../components/Alert";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button";
import { showInfoBox } from "../../store/UI";
import { removeFromOrderRequest } from "../../store/Order";

type Props = {
	orderId: number;
	orderItem: OrderItemBean;
	designId: number;
};
const OrderItem = (props: Props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const { t } = useTranslation();
	const orderId = props.orderId;
	const orderItem = props.orderItem;
	const userDesign = orderItem.design;
	const designId = userDesign.id;
	const production = orderItem.production;
	const productionRange = orderItem.production_range;

	// GLOBAL STATE
	const selectedProductionRange = useSelector(
		(state: RootState) => state.productionRange.selectedRange
	);

	const isSample = production !== null ? production.sample : true;

	// LOCAL STATE
	const [sample, setSample] = useState(isSample);

	// HANDLERS
	const handleOrderSample = (sample: boolean) => {
		setSample(sample);

		if (!sample && selectedProductionRange) {
			handleOrderQuantity(selectedProductionRange);
		}
	};

	const handleOrderQuantity = (range: ProductionRange) => {
		dispatch(selectProductionRange(range));
	};

	const handleDeleteItem = (itemId: number) => {
		dispatch(
			removeFromOrderRequest({
				orderId: orderId,
				item: itemId,
			})
		);
	};

	const renderCardActions = () => {
		return userDesign?.id ? (
			<>
				<Button
					style={ButtonEnum.link}
					onClick={() => userDesign && navigate(goToEditDesign(userDesign.id))}
				>
					{t("button.edit")}
				</Button>
				<Button
					style={ButtonEnum.link}
					onClick={() => alert("not ready yet")} // TODO
				>
					{t("button.duplicate")}
				</Button>
				<Button
					style={ButtonEnum.link}
					onClick={() => handleDeleteItem(props.orderItem.id)}
				>
					{t("button.remove")}
				</Button>
			</>
		) : null;
	};

	return (
		<Card title={userDesign?.name} active action={renderCardActions()}>
			{userDesign && production && (
				<ProductionDesigns
					edit
					production={production}
					userDesign={userDesign}
				/>
			)}

			{productionRange && productionRange.length > 0 ? (
				<div className="order-type">
					<SubsectionTitle title={t("orderProduction.orderType")} />
					<div className="order-type-options">
						<ActionCard
							onClick={() => handleOrderSample(true)}
							key={"sample-production"}
							type={ActionCardEnum.expanded}
							title={t("orderProduction.orderSample")}
							label={t("orderProduction.orderSampleDescription")}
							selected={sample}
						/>
						<ActionCard
							onClick={() => handleOrderSample(false)}
							key={"order-production"}
							type={ActionCardEnum.expanded}
							title={t("orderProduction.orderProduction")}
							label={t("orderProduction.orderProductionDescription")}
							selected={!sample}
						/>
					</div>
					{selectedProductionRange && (
						<div className="order-type-info">
							<Alert type={AlertEnum.success}>
								{sample ? (
									<span
										dangerouslySetInnerHTML={{
											__html: t("orderProduction.alertSample"),
										}}
									></span>
								) : (
									<span
										dangerouslySetInnerHTML={{
											__html: t("orderProduction.alertProduction"),
										}}
									></span>
								)}
							</Alert>
						</div>
					)}
				</div>
			) : (
				<LoadingOptions />
			)}
			{/* IF ORDER-TYPE */}
			{productionRange && productionRange.length > 0 ? (
				<div className="order-quantity">
					{!sample ? (
						<>
							<SubsectionTitle title={t("orderProduction.sizeSelection")}>
								<Button
									style={ButtonEnum.link}
									onClick={() =>
										dispatch(
											showInfoBox({
												open: true,
												title: t("orderProduction.sizeGuide"),
												key: "size_guide",
											})
										)
									}
								>
									{t("orderProduction.sizeGuide")}
								</Button>
							</SubsectionTitle>
							{production && orderId && (
								<div className="size-selector-container">
									<SizeSelector
										orderId={orderId}
										designId={designId}
										production={production}
										productionRange={productionRange}
										selectedProductionRange={selectedProductionRange}
									/>
								</div>
							)}
						</>
					) : (
						<>
							<SubsectionTitle title={t("orderProduction.sizeSelection")}>
								<Button
									style={ButtonEnum.link}
									onClick={() =>
										dispatch(
											showInfoBox({
												open: true,
												title: t("orderProduction.sizeGuide"),
												key: "size_guide",
											})
										)
									}
								>
									{t("orderProduction.sizeGuide")}
								</Button>
							</SubsectionTitle>
							{orderId && (
								<SampleSizeSelector
									orderId={orderId}
									production={production}
									designId={designId}
									productionRange={productionRange}
								/>
							)}
						</>
					)}
				</div>
			) : (
				<LoadingOptions />
			)}
		</Card>
	);
};

export default OrderItem;
