import styled from "styled-components";

export const Wrap = styled.div<{ $active?: boolean; $sticky?: boolean }>`
	position: ${(props) => (props.$sticky ? "sticky" : "static")};
	top: 76px;
	display: flex;
	flex-direction: column;
	border-radius: 4px;
	background: var(--white);
	width: 100%;
	box-sizing: border-box;
	.card-header {
		box-sizing: border-box;
		display: flex;
		align-items: center;
		border-bottom: 1px solid var(--purple30);
		padding: 16px 24px;
		height: 44px;
		.card-title {
			color: var(--gray130);
			font-weight: ${(props) => (props.$active ? "bold" : "normal")};
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.action {
				display: flex;
				gap: 8px;
			}
		}
	}
	.card-container {
		box-sizing: border-box;
		display: block;
		padding: 24px;
		display: flex;
		flex-direction: column;
	}
`;
