import React from "react";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { AppDispatch, RootState } from "../../store";

import Card from "../../components/Card";
import PaddingManager from "../../components/PaddingManager";
import ProductionDesigns from "../../components/ProductionDesigns";
import ProductionSummary from "../../components/ProductionSummary";
import { Wrap } from "./styles";
import { useEffect } from "react";
import {
	getOrderCompletedRequest,
	getOrderSummaryRequest,
} from "../../store/Order";
import { getUserDesignRequest } from "../../store/UserDesign";
import { getProductionByIdRequest } from "../../store/Production";
import ShippingDetails from "../../components/ShippingDetails";
import { getShippingFromOrderThunk } from "../../store/Shipping";
import { getBillingFromOrderThunk } from "../../store/Billing";
import { UserDesign } from "../../beans";
import BillingDetails from "../../components/BillingDetails";
import { dateFormat } from "../../utils/format";
// import LoadingOptions from "../../components/LoadingOptions";

const OrderCompleted = () => {
	const dispatch = useDispatch<AppDispatch>();

	const user = useSelector((state: RootState) => state.user.data);
	const userDesign = useSelector((state: RootState) => state.userDesign.data);
	const production = useSelector((state: RootState) => state.production.data);
	const order = useSelector((state: RootState) => state.order.data);
	const orderConfirmation = useSelector(
		(state: RootState) => state.order.confirmation
	);
	const summary = useSelector((state: RootState) => state.order.summary);
	const orderSummary = summary?.costs;

	const { orderId } = useParams();

	useEffect(() => {
		if (orderId) {
			dispatch(getOrderCompletedRequest(orderId));
			dispatch(getShippingFromOrderThunk(orderId));
			dispatch(getBillingFromOrderThunk(orderId));
			dispatch(getOrderSummaryRequest(orderId));
		}
	}, [orderId, dispatch]);

	useEffect(() => {
		if (orderSummary && orderSummary?.designs?.length > 0) {
			dispatch(getUserDesignRequest(orderSummary.designs[0].design_id));
		}
	}, [orderSummary, dispatch]);

	useEffect(() => {
		if (userDesign) {
			const productionId = getProductionIdFromOrder(
				Number(orderId),
				userDesign
			);

			if (productionId)
				dispatch(
					getProductionByIdRequest({
						designId: userDesign.id,
						productionId: productionId,
					})
				);
		}
	}, [userDesign]); // eslint-disable-line react-hooks/exhaustive-deps

	const getProductionIdFromOrder = (
		orderId: number,
		userDesign: UserDesign
	) => {
		const production = userDesign.production_order_details.productions.find(
			(prod) => prod.order === orderId
		);

		return production?.production_id;
	};
	return (
		<>
			<Header />
			<Wrap>
				<PaddingManager>
					<Card>
						<div className="order-container">
							<div className="heading-md">Order confirmed</div>

							<div className="body-sm">
								A confirmation email has been sent to:{" "}
								<span className="title-sm">{user?.email}</span>.
							</div>
							{order?.order_placed_date && (
								<div className="body-sm">
									Order date:{" "}
									<span className="title-sm">
										{dateFormat(order?.order_placed_date)}
									</span>
								</div>
							)}
							{order?.estimated_delivery_date && (
								<div className="body-sm">
									Estimated delivery date:{" "}
									<span className="title-sm">
										{dateFormat(order?.estimated_delivery_date)}
									</span>
								</div>
							)}
							<div className="body-sm">
								Order number: <span className="title-sm">{orderId}</span>
							</div>
						</div>
					</Card>

					<div className="order-details-container">
						<Card title="Order details">
							{orderId && (
								<div className="order-details">
									<ShippingDetails orderId={orderId} />
									<BillingDetails orderId={orderId} />
									{orderConfirmation && (
										<div className="payment">{orderConfirmation.status}</div>
									)}
								</div>
							)}
						</Card>
						<Card title="Products">
							{summary?.costs?.designs &&
								summary?.costs?.designs?.map((design: UserDesign) => (
									<ProductionDesigns userDesign={design} key={design.id} />
								))}
						</Card>

						<div className="order-summary">
							<ProductionSummary summary={summary} orderId={orderId} />
						</div>
					</div>
				</PaddingManager>
			</Wrap>
		</>
	);
};

export default OrderCompleted;
