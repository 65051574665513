import React from "react";
import { useEffect } from "react";
import { getOrderByIdRequest, getOrderSummaryRequest } from "../../store/Order";
import Header from "../../components/Header";
import { ButtonEnum, StripePaymentType } from "../../beans";
import PaddingManager from "../../components/PaddingManager";
import SubsectionTitle from "../../components/SubsectionTitle";
import { Wrap } from "./styles";
import { stripePublishableKey } from "../../constants/endpoints";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../../components/CheckoutForm";
import Card from "../../components/Card";
import Button from "../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { AppDispatch, RootState } from "../../store";
import ProductionSummary from "../../components/ProductionSummary";
import ShippingDetails from "../../components/ShippingDetails";
import { goToOrderShipping } from "../../constants/routes";
import BillingDetails from "../../components/BillingDetails";
import { useTranslation } from "react-i18next";

const Checkout = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const { t } = useTranslation();
	const { orderId } = useParams();
	const summary = useSelector((state: RootState) => state.order.summary);

	// HOOKS
	useEffect(() => {
		if (orderId) {
			dispatch(getOrderByIdRequest(orderId));
			dispatch(getOrderSummaryRequest(orderId));
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const editShippingButton = orderId && (
		<Button
			style={ButtonEnum.link}
			onClick={() => navigate(goToOrderShipping(orderId))}
		>
			{t("button.edit")}
		</Button>
	);

	const stripePromise = loadStripe(
		stripePublishableKey ? stripePublishableKey : ""
	);

	const options = {
		payment_method_types: ["card"],
		mode: "payment" as StripePaymentType,
		amount: 666,
		currency: "eur",
	};

	return (
		<>
			<Header />
			<Wrap>
				<PaddingManager>
					<div className="page-containers">
						<div className="page-content">
							<Card
								title={t("checkout.shippingInfo")}
								action={editShippingButton}
							>
								{orderId && (
									<div className="production-details">
										<ShippingDetails orderId={orderId} />
										<BillingDetails orderId={orderId} />
									</div>
								)}
							</Card>
							<Card title={t("checkout.paymentInfo")} active>
								<SubsectionTitle title={t("checkout.title")}></SubsectionTitle>
								<Elements stripe={stripePromise} options={options}>
									<CheckoutForm />
								</Elements>
							</Card>
						</div>

						<ProductionSummary summary={summary} orderId={orderId} />
					</div>
				</PaddingManager>
			</Wrap>
		</>
	);
};

export default Checkout;
