import React from "react";
import { MouseEventHandler } from "react";
import { Wrap } from "./styles";

type Props = {
	hoverButton: React.ReactNode;
	menuTitle?: string;
	children?: React.ReactNode;
	onClick?: MouseEventHandler<HTMLDivElement>;
};

const MenuPopover = (props: Props) => {
	const { hoverButton, menuTitle, children } = props;

	return (
		<Wrap>
			<div className="menu-popover">
				<div className="menu-popover__header">{hoverButton}</div>
				<div className="menu-popover__content">
					{menuTitle && (
						<div className="menu-popover__title title-sm">{menuTitle}</div>
					)}
					{children}
				</div>
			</div>
		</Wrap>
	);
};

export default MenuPopover;
