import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Fabric, Fabrics, UserDesign } from "../../beans";
import { apiUrl } from "../../constants/endpoints";
import { put, call, StrictEffect, takeEvery } from "redux-saga/effects";

import { getAuthHeader } from "../../api/auth";
import { getUserDesignRequest, update } from "../UserDesign";
import { showErrorModal } from "../UI";
import { getErrors } from "../../utils/errors";
import { getProductionRangeThunk } from "../ProductionRange";
import { getCurrentCartRequest } from "../Order";

// Define the initial state
export interface FabricState {
	data: null | Fabrics[];
	error: null | string;
	selectedFabric: null | Fabrics;
	selectedColor: null | Fabric;
}

const initialState: FabricState = {
	data: null,
	error: null,
	selectedFabric: null,
	selectedColor: null,
};

export const fabricSlice = createSlice({
	name: "fabric",
	initialState,
	reducers: {
		resetFabric: (state) => {
			state.data = initialState.data;
			state.selectedFabric = initialState.selectedFabric;
			state.selectedColor = initialState.selectedColor;
		},
		selectFabric: (state, action: PayloadAction<Fabrics>) => {
			state.selectedFabric = action.payload;
		},
		selectFabricColor: (
			state,
			action: PayloadAction<{ coloredFabric: Fabric; userDesign: UserDesign }>
		) => {
			state.selectedColor = action.payload.coloredFabric;
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		fetchFabricRequest: (state, _action) => {
			state.error = null;
		},
		fetchFabricSuccess: (state, action: PayloadAction<Fabrics[]>) => {
			state.data = action.payload;
			state.selectedFabric = state.selectedFabric?.code
				? state.selectedFabric
				: action.payload[0];
			state.selectedColor = state.selectedFabric?.code
				? state.selectedFabric.coloredfabric_set[0]
				: action.payload[0].coloredfabric_set[0];
		},
		fetchFabricFailure: (state, action: PayloadAction<string>) => {
			state.error = action.payload;
		},
	},
});

// Define the saga
function* fetchFabricSaga(
	action: PayloadAction<string>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<StrictEffect, void, any> {
	const designId = action.payload;

	try {
		const headers = getAuthHeader();
		const response = yield call(
			fetch,
			`${apiUrl}/designs/userdesigns/${designId}/fabric/`,
			{
				headers: headers,
			}
		);
		const data = yield response.json();
		yield put(fetchFabricSuccess(data));
		yield put(getProductionRangeThunk(Number(designId)));
	} catch (error: unknown) {
		// console.error("ERRROR FABBRIC", error);
		if (error instanceof Error) {
			yield put(fetchFabricFailure(error.message));
		} else {
			yield put(fetchFabricFailure("An unknown error occurred"));
		}
	}
}

function* updateFabricSaga(
	action: PayloadAction<{ coloredFabric: Fabric; userDesign: UserDesign }>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<StrictEffect, void, any> {
	try {
		const { coloredFabric, userDesign } = action.payload;
		if (userDesign) {
			const headers = getAuthHeader();
			const response = yield call(
				fetch,
				`${apiUrl}/designs/userdesigns/${userDesign.id}/coloredfabric/select/`,
				{
					method: "POST",
					body: JSON.stringify({
						colored_fabric_id: coloredFabric.id,
					}),
					headers: headers,
					credentials: "include",
				}
			);
			const data = yield response.json();

			if (response.ok) {
				yield put(fetchFabricRequest(userDesign.id));
				yield put(update(data));
				yield put(getUserDesignRequest(userDesign.id));
				yield put(getCurrentCartRequest());
			} else {
				yield put(showErrorModal(getErrors(data)));
			}
		}
	} catch (error: unknown) {
		if (error instanceof Error) {
			yield put(showErrorModal(error.message));
			yield put(fetchFabricFailure(error.message));
		} else {
			yield put(showErrorModal("An unknown error occurred"));
			yield put(fetchFabricFailure("An unknown error occurred"));
		}
	}
}

export const {
	resetFabric,
	selectFabric,
	selectFabricColor,
	fetchFabricRequest,
	fetchFabricSuccess,
	fetchFabricFailure,
} = fabricSlice.actions;

export default fabricSlice.reducer;

export function* sagas() {
	yield takeEvery(fetchFabricRequest.type, fetchFabricSaga);
	yield takeEvery(selectFabricColor.type, updateFabricSaga);
}
