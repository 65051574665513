import React from "react";
import { useEffect, useState } from "react";
import { Wrap } from "./styles";
import { AppDispatch } from "../../store";
import { useDispatch } from "react-redux";
import { ActionCardEnum, Production, ProductionRange } from "../../beans";

import ActionCard from "../ActionCard";
import { setProductionRequest } from "../../store/Production";
import { priceFormat } from "../../utils/format";
import Divider from "../Divider";
import { getKeyFromValue } from "../../utils/objects";

type Props = {
	orderId: number;
	production: Production;
	designId: number;
	productionRange: ProductionRange[];
};

const SampleSizeSelector = (props: Props) => {
	const dispatch = useDispatch<AppDispatch>();
	const { production, productionRange } = props;
	const productionId = props.production.id;

	const [sampleSize, setSampleSize] = useState<string>(
		getKeyFromValue(production.sizes, 1) || "small"
	);

	useEffect(() => {
		const initialSampleProductionPayload = {
			small: 0,
			medium: 0,
			large: 0,
			x_large: 0,
		};

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(initialSampleProductionPayload as any)[sampleSize] = 1;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(initialSampleProductionPayload as any)["sample"] = true;

		dispatch(
			setProductionRequest({
				productionId: productionId,
				orderId: props.orderId,
				designId: props.designId,
				body: initialSampleProductionPayload,
			})
		);
	}, [sampleSize]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Wrap>
			<div className="size-editor">
				<ActionCard
					title="S"
					type={ActionCardEnum.simple}
					selected={sampleSize === "small"}
					onClick={() => setSampleSize("small")}
				/>
				<ActionCard
					title="M"
					type={ActionCardEnum.simple}
					selected={sampleSize === "medium"}
					onClick={() => setSampleSize("medium")}
				/>
				<ActionCard
					title="L"
					type={ActionCardEnum.simple}
					selected={sampleSize === "large"}
					onClick={() => setSampleSize("large")}
				/>
				<ActionCard
					title="XL"
					type={ActionCardEnum.simple}
					selected={sampleSize === "x_large"}
					onClick={() => setSampleSize("x_large")}
				/>
			</div>
			<Divider vertical />

			<div className="total-row" key={`total-size`}>
				<label className="label-sm">Total units</label>
				<div className="total">
					<strong>1</strong>
				</div>
			</div>
			{productionRange && (
				<div className="total-row">
					<div className="label-sm">Price per unit</div>

					<div className="total">
						<strong>
							{priceFormat(productionRange[0].unit_total?.toString())} €
						</strong>
					</div>
				</div>
			)}
		</Wrap>
	);
};

export default SampleSizeSelector;
