import React from "react";
import { ForwardedRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AppDispatch, RootState } from "../../store";
import { setProductionRequest } from "../../store/Production";
import { selectProductionRange } from "../../store/ProductionRange";
import { translateSize } from "../../utils/translate";
import {
	AlertEnum,
	Production,
	ProductionRange,
	ProductionRangeAvailability,
} from "../../beans";
import Alert from "../Alert";
import { Wrap } from "./styles";

import { sizeSelectorSchema, sizesSelectorForm } from "../../utils/form";
import { calculateTotalProductionItems } from "../../utils/orders";
import { useTranslation } from "react-i18next";
import Divider from "../Divider";
import { priceFormat } from "../../utils/format";

import LoadingItem from "../LoadingItem";
import {
	initialProduction_100,
	initialProduction_200,
	initialProduction_300,
	initialProduction_50,
} from "../../constants/productions";

export type Ref = ForwardedRef<HTMLFormElement>;

type Props = {
	orderId: number;
	designId: number;
	production: Production;
	productionRange: ProductionRange[];
	selectedProductionRange: ProductionRange | null;
};

const SizeSelector = (props: Props) => {
	const dispatch = useDispatch<AppDispatch>();
	const { t } = useTranslation();

	const {
		orderId,
		designId,
		production,
		productionRange,
		selectedProductionRange,
	} = props;

	const [total, setTotal] = useState(0);
	const [error, setError] = useState("");

	const [availableSizes, setAvailableSizes] =
		useState<ProductionRangeAvailability>();

	const selectedRange = useSelector(
		(state: RootState) => state.productionRange.selectedRange
	);

	const availableRange = useSelector(
		(state: RootState) => state.productionRange.availableRange
	);

	const minimum = useSelector(
		(state: RootState) => state.productionRange.selectedRange?.minimum
	);
	// HOOKS

	useEffect(() => {
		getTotal(getValues());
		if (productionRange.length > 0 && !selectedProductionRange) {
			dispatch(selectProductionRange(productionRange[1]));
		}
		minimum && setDefaultProdutcQuantity(minimum);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		getAvailableSizes();
		// if (production?.sizes) return;
		if (minimum && total < minimum) {
			setDefaultProdutcQuantity(minimum);
		}
	}, [selectedProductionRange, availableRange]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!minimum) return;
		if (getTotal(production.sizes as sizesSelectorForm) < minimum) {
			reset(initialProduction_50);
			setFormData(initialProduction_50);
		} else {
			reset(production.sizes as sizesSelectorForm);
			setFormData(production.sizes as sizesSelectorForm);
		}

		getTotal(getValues());
	}, [selectedProductionRange, production]); // eslint-disable-line react-hooks/exhaustive-deps

	const [formData, setFormData] = useState<sizesSelectorForm>({
		x_small: production?.sizes?.x_small || 0,
		small: production?.sizes?.small || 0,
		medium: production?.sizes?.medium || 0,
		large: production?.sizes?.large || 0,
		x_large: production?.sizes?.x_large || 0,
	});

	useEffect(() => {
		if (total && minimum) {
			total < minimum
				? setError(t("sizeSelector.error", { total, minimum }))
				: setError("");
		}
	}, [total, minimum, t]);

	const getTotal = (data: sizesSelectorForm): number => {
		const sum = calculateTotalProductionItems(data);
		setTotal(sum);
		return sum;
	};

	useEffect(() => {
		if (production?.id) {
			if (total > 1)
				dispatch(
					setProductionRequest({
						designId: designId,
						productionId: production?.id,
						orderId: orderId,
						body: formData,
					})
				);
		}
	}, [formData]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleInputChange = (data: sizesSelectorForm) => {
		setTimeout(() => {
			setFormData(data);
			getTotal(data);
		}, 300);
	};

	const getRangeFromTotal = () => {
		if (minimum && total < minimum) return productionRange[1];

		const range = productionRange.find((range) => range.minimum > total);

		if (range) return range;
		return productionRange[productionRange.length - 1];
	};

	const setDefaultProdutcQuantity = (minimum: number) => {
		const payload = {
			productionId: production?.id || 0,
			orderId: orderId,
			body: initialProduction_100,
			designId: designId,
		};

		setFormData(initialProduction_50);
		switch (minimum) {
			case 50:
				dispatch(setProductionRequest(payload));
				setFormData(initialProduction_50);
				return;
			case 100:
				dispatch(setProductionRequest(payload));
				setFormData(initialProduction_100);
				return;
			case 151:
			case 200:
				payload.body = initialProduction_200;
				dispatch(setProductionRequest(payload));
				setFormData(initialProduction_200);
				return;
			case 301:
				payload.body = initialProduction_300;
				dispatch(setProductionRequest(payload));
				setFormData(initialProduction_300);

				return;
			default:
				payload.body = initialProduction_100;
				dispatch(setProductionRequest(payload));
				setFormData(initialProduction_100);
				return;
		}
	};

	const setErrorClass = (k: keyof sizesSelectorForm) => {
		let errorClass = "";
		if (
			touchedFields[k as keyof sizesSelectorForm] &&
			errors[k as keyof sizesSelectorForm]
		)
			errorClass = "error";

		return errorClass;
	};

	const renderErrors = () => {
		return Object.keys(errors).map((key) => {
			const typedKey = key as keyof sizesSelectorForm;
			return (
				<Alert type={AlertEnum.error} key={typedKey}>
					{errors[typedKey]?.message}
				</Alert>
			);
		});
	};

	const {
		register,
		handleSubmit,
		reset,
		getValues,
		formState: { errors, touchedFields },
	} = useForm<sizesSelectorForm>({
		resolver: yupResolver(sizeSelectorSchema),
		defaultValues: production?.sizes,
		shouldFocusError: false,
	});

	const onSubmit = (data: sizesSelectorForm) => {
		handleInputChange(data);
		getTotal(data);
	};

	const getAvailableSizes = () => {
		const range = availableRange.find(
			(range) => range.min === selectedRange?.minimum
		);
		if (range) setAvailableSizes(range);
	};

	return production?.sizes ? (
		<Wrap>
			<div className="sizes-editor">
				<form onChange={handleSubmit(onSubmit)}>
					<div className="form-row">
						<div className="size-row">
							{Object.keys(production.sizes).map(function (key, i: number) {
								const typedKey = key as keyof sizesSelectorForm;

								if (
									availableSizes &&
									availableSizes[key as keyof ProductionRangeAvailability]
								)
									return (
										<div className="input-row" key={`${key}_${i}`}>
											<label className="label-sm">{translateSize(key)}</label>
											<input
												className={`${setErrorClass(typedKey)} size-selector-input`}
												type="text"
												{...register(typedKey)}
											/>
										</div>
									);

								return null;
							})}
						</div>
						<Divider vertical />
						<div className="total-row" key={`total-size`}>
							<label className="label-sm">Total units</label>
							<div className="total">
								<strong>{total}</strong>
							</div>
						</div>
						{selectedProductionRange && (
							<div className="total-row">
								<div className="label-sm">Price per unit</div>

								<div className="total">
									{productionRange[1].item_lowest_cost ===
									getRangeFromTotal()?.item_lowest_cost ? (
										<strong>
											{priceFormat(
												selectedProductionRange?.item_lowest_cost?.toString()
											)}{" "}
											€
										</strong>
									) : (
										<>
											<s>
												{priceFormat(productionRange[1].item_lowest_cost)} €
											</s>{" "}
											<strong>
												{priceFormat(getRangeFromTotal()?.item_lowest_cost)} €
											</strong>
										</>
									)}
								</div>
							</div>
						)}
					</div>
				</form>
			</div>
			{errors && renderErrors()}
			{error && <Alert type={AlertEnum.error}>{error}</Alert>}
		</Wrap>
	) : (
		<LoadingItem />
	);
};

export default SizeSelector;
