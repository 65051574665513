import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { AppDispatch, RootState } from "../../store";
import {
	hideActionModal,
	resetActionModal,
	selectTab,
	showActionModal,
} from "../../store/UI";
import { update } from "../../store/UserDesign";
import { addDesign, removeDesign } from "../../store/Design";
import { deleteDesignRequest } from "../../store/Design";
import { goToEditDesign, goToOrderProduction } from "../../constants/routes";
import { hasClosedOrder } from "../../utils/orders";
import { ArtworkDetail, ButtonEnum, TabEnum, UserDesign } from "../../beans";
import ArtworkPreview from "../ArtworkPreview";
import Button from "../Button";
import { Wrap } from "./styles";
import { useTranslation } from "react-i18next";
import Icon from "../Icon";
import { dots, duplicate, trash } from "../../constants/icons";
import MenuPopover from "../MenuPopover";
import EditDesignName from "../EditDesignName";
import { addToOrderRequest } from "../../store/Order";

type Props = {
	design: UserDesign;
};
const DesignPreview = (props: Props) => {
	const { design } = props;
	const multiplePrint = design.print_set.length > 1;

	const dispatch = useDispatch<AppDispatch>();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const order = useSelector((state: RootState) => state.order.data);
	const selectedDesigns = useSelector(
		(state: RootState) => state.design.selected
	);

	const isSelected = () => {
		return selectedDesigns.find((d) => d.id === design.id) ? true : false;
	};

	const deleteDesign = (id: number) => {
		dispatch(resetActionModal());
		dispatch(deleteDesignRequest(id));
	};

	const openDeleteModal = (id: number) => {
		dispatch(
			showActionModal({
				title: t("designPreview.deleteModal.title"),
				error: `<div class="title-sm">${t("designPreview.deleteModal.contenTitle", { count: 1 })}</div><p class="label-lg">${t("designPreview.deleteModal.contenDescription", { count: 1 })}</p>`,
				actions: [
					{
						style: ButtonEnum.secondary,
						onClick: () => dispatch(hideActionModal()),
						label: t("designPreview.deleteModal.keep"),
					},
					{
						style: ButtonEnum.danger,
						onClick: () => deleteDesign(id),
						label: t("designPreview.deleteModal.delete"),
					},
				],
			})
		);
	};

	const editDesign = (design: UserDesign) => {
		dispatch(update(design));
		dispatch(selectTab(TabEnum.summary));
		navigate(goToEditDesign(design.id));
	};

	const toggleDesign = (
		e: React.ChangeEvent<HTMLInputElement>,
		design: UserDesign
	) => {
		if (e.target.checked) {
			dispatch(addDesign(design));
		} else {
			dispatch(removeDesign(design));
		}
	};

	return (
		<Wrap $selected={isSelected()}>
			<div className="checkbox">
				<input
					type="checkbox"
					name={String(design.id)}
					checked={isSelected()}
					onChange={(e) => toggleDesign(e, design)}
				/>
			</div>
			<div className="dot-menu">
				<MenuPopover
					hoverButton={
						<Button style={ButtonEnum.icon} onClick={() => null}>
							<Icon icon={dots} />
						</Button>
					}
				>
					<Button
						style={ButtonEnum.menu}
						onClick={() => {
							alert("Duplicate");
						}}
					>
						<Icon icon={duplicate} />
						{t("button.duplicate")}
					</Button>
					<Button
						className="delete"
						style={ButtonEnum.menu}
						onClick={() => {
							openDeleteModal(design.id);
						}}
					>
						<Icon icon={trash} />
						{t("button.delete")}
					</Button>
				</MenuPopover>
			</div>
			<div
				className={
					multiplePrint ? `multiple design-preview-card` : `design-preview-card`
				}
			>
				{design.print_set.length ? (
					design.print_set.map((artwork: ArtworkDetail, k: number) => {
						return (
							<div className="artwork" key={k}>
								<ArtworkPreview artwork={artwork} design={design} />
							</div>
						);
					})
				) : (
					<ArtworkPreview design={design} />
				)}
				<div className="actions">
					{/* EDIT */}
					{!hasClosedOrder(design) && (
						<Button
							style={ButtonEnum.secondary}
							onClick={() => editDesign(design)}
						>
							{t("button.edit")}
						</Button>
					)}
					{/* DELETE */}
					{!hasClosedOrder(design) && (
						<Button
							style={ButtonEnum.primary}
							onClick={() => {
								dispatch(
									addToOrderRequest({ orderId: order?.id, design: [design.id] })
								);
							}}
						>
							{t("button.addToCart")}
						</Button>
					)}
					{hasClosedOrder(design) && order && order?.id && (
						<Button
							style={ButtonEnum.secondary}
							onClick={() => {
								navigate(goToOrderProduction(order?.id));
							}}
						>
							{t("button.showOrder")}
						</Button>
					)}
				</div>
			</div>
			<EditDesignName design={design} />
		</Wrap>
	);
};

export default DesignPreview;
