import React, { useEffect, useState } from "react";
import { Wrap } from "./styles";
import { AppDispatch, RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import {
	Fabrics as FabricsType,
	Fabric as FabricType,
	UserDesign,
} from "../../beans";
import Swatch from "../Swatch";
import SubsectionTitle from "../SubsectionTitle";
import {
	fetchFabricRequest,
	selectFabric,
	selectFabricColor,
} from "../../store/Fabric";
import Divider from "../Divider";
import LoadingOptions from "../LoadingOptions";

import { useTranslation } from "react-i18next";

type Props = {
	design: UserDesign;
};

const Fabric = (props: Props) => {
	const dispatch = useDispatch<AppDispatch>();
	const { t } = useTranslation();
	const { design } = props;
	const fabrics = useSelector((state: RootState) => state.fabrics.data);

	const getColoredFabricSet = () => {
		fabrics;
		const myselectedFabric = fabrics?.find(
			(fabric) => fabric.name === design?.item_fabric.col_fabric.fabric
		);
		if (myselectedFabric) {
			return myselectedFabric.coloredfabric_set;
		}
	};

	const [coloredFabrics, setColoredFabrics] = useState(getColoredFabricSet());

	const handleSelectFabric = (fabric: FabricsType) => {
		dispatch(selectFabric(fabric));
		dispatch(
			selectFabricColor({
				coloredFabric: fabric.coloredfabric_set[0],
				userDesign: design,
			})
		);
	};

	const handleSelectFabricColor = (fabric: FabricType) => {
		dispatch(selectFabricColor({ coloredFabric: fabric, userDesign: design }));
	};
	useEffect(() => {
		setColoredFabrics(getColoredFabricSet());
		if (!fabrics) dispatch(fetchFabricRequest(design.id.toString()));
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setColoredFabrics(getColoredFabricSet());
	}, [fabrics]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Wrap>
			{fabrics ? (
				<>
					<SubsectionTitle title={t("fabric.title")} />
					<div className="fabric-swatches">
						{fabrics.map((fabric: FabricsType) => {
							return (
								<Swatch
									info
									title={fabric.name}
									description={fabric.description}
									onClick={() => handleSelectFabric(fabric)}
									image={fabric.fabric_image_url}
									key={fabric.code}
									code={fabric.code}
									selected={
										design?.item_fabric.col_fabric.fabric === fabric.name
									}
								/>
							);
						})}
					</div>
					<Divider />
					{coloredFabrics ? (
						<>
							<SubsectionTitle title={t("fabric.color")} />
							<div className="fabric-swatches">
								{coloredFabrics.map((coloredFabric) => {
									return (
										<Swatch
											onClick={() => handleSelectFabricColor(coloredFabric)}
											color={coloredFabric.color}
											title={`${coloredFabric.color.name}  ${coloredFabric.id}`}
											image={coloredFabric.image_url}
											key={coloredFabric.id}
											selected={
												coloredFabric.id === design?.item_fabric.col_fabric.id
											}
										/>
									);
								})}
							</div>
						</>
					) : (
						<LoadingOptions />
					)}
				</>
			) : (
				<LoadingOptions />
			)}
		</Wrap>
	);
};

export default Fabric;
