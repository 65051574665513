import styled from "styled-components";
import { breakpoints } from "../../constants/breakpoints";

export const Wrap = styled.div<{ $selected?: boolean; $disabled?: boolean }>`
	.menu-popover__header {
		display: flex;
		flex-direction: column;
		align-items: end;
		z-index: 9;
		white-space: nowrap;
	}
	.menu-popover__title {
		margin-bottom: 8px;
		white-space: nowrap;
		padding: 0px 8px;
	}
	.menu-popover__content {
		position: absolute;

		right: 0;
		background: var(--white);
		width: 140px;
		padding: 8px;
		border-radius: 4px;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
		display: none;
		flex-direction: column;
		align-items: start;
		justify-content: center;
		opacity: 0;

		.delete {
			color: var(--red100);
			svg {
				path {
					fill: var(--red100);
				}
			}
		}
	}
	&:hover {
		.menu-popover__content {
			display: flex;
			opacity: 1;
		}
	}

	@media only screen and (max-width: ${breakpoints.desktopWide}px) {
	}

	@media only screen and (max-width: ${breakpoints.desktop}px) {
	}
`;
