import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { nextTab, prevTab } from "../../store/UI";
import { ButtonEnum, TabEnum } from "../../beans";
import Button from "../Button";
import { useNavigate } from "react-router";
import { Wrap } from "./styles";
import { goToOrderProduction } from "../../constants/routes";
import ProductionRangeItem from "../ProductionRangeItem";
import { useEffect } from "react";
import { addToOrderRequest } from "../../store/Order";
import { getPendingOrder } from "../../utils/orders";
import { useTranslation } from "react-i18next";

const NavigationBar = () => {
	const dispatch = useDispatch<AppDispatch>();
	const { t } = useTranslation();

	const navigate = useNavigate();
	const selectedTab = useSelector((state: RootState) => state.ui.tab.selected);
	const userDesign = useSelector((state: RootState) => state.userDesign.data);
	const production = useSelector((state: RootState) => state.production.data);
	const order = useSelector((state: RootState) => state.order.data);

	const productionRange = useSelector(
		(state: RootState) => state.productionRange.data
	);

	const handleNewOrder = (e: React.MouseEvent<HTMLButtonElement>) => {
		const button = e.target as HTMLButtonElement;

		button.setAttribute("disabled", "true");
		if (userDesign?.id && order?.id) {
			dispatch(
				addToOrderRequest({ orderId: order.id, design: [userDesign.id] })
			);
		}
	};

	useEffect(() => {
		if (userDesign?.id && order?.id && production?.id) {
			const pendingOrder = getPendingOrder(userDesign);
			if (!pendingOrder) {
				navigate(goToOrderProduction(Number(order?.id)));
			}
		}
	}, [order]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		userDesign && (
			<Wrap>
				<div className="button-container">
					<>
						<div className="range-item">
							<ProductionRangeItem productionRangeItem={productionRange[1]} />
						</div>
						<Button
							style={ButtonEnum.secondary}
							disabled={selectedTab === TabEnum.fabric}
							onClick={() => dispatch(prevTab())}
						>
							{t("button.back")}
						</Button>
						<Button
							style={ButtonEnum.primary}
							onClick={(e) => {
								selectedTab === TabEnum.summary
									? handleNewOrder(e)
									: dispatch(nextTab());
							}}
						>
							{t("button.next")}
						</Button>
					</>
				</div>
			</Wrap>
		)
	);
};
export default NavigationBar;
