import React from "react";
import { ChangeEvent, DragEvent, useEffect, useRef, useState } from "react";
import { AlertEnum, ArtworkDetail } from "../../beans";
import { updateArtworkDetailRequest } from "../../store/ArtworkDetail";
import { RootState, AppDispatch } from "../../store";
import { useSelector, useDispatch } from "react-redux";
import Alert from "../Alert";
import { Wrap } from "./styles";

import { createUserDesignLabelThunk } from "../../store/UserDesign";
import { useTranslation } from "react-i18next";

type Props = {
	label?: boolean;
};
const FileDrop = (props: Props) => {
	const dispatch = useDispatch<AppDispatch>();
	const { t } = useTranslation();

	const label = props.label;
	//LOCAL STATE
	const [isOver, setIsOver] = useState(false);
	const [hasErrors, setHasErrors] = useState(false);
	const inputFile = useRef<HTMLInputElement>(null);

	// GLOBAL STATE
	const userDesign = useSelector((state: RootState) => state.userDesign.data);
	const selectedItem = useSelector(
		(state: RootState) => state.userDesign?.data?.item_fabric?.item
	);

	const artworkDetailErrors = useSelector(
		(state: RootState) => state.artworkDetails.error
	);
	const selectedPrintType = useSelector(
		(state: RootState) => state.printType.selected
	);
	const selectedPrintLocation = useSelector(
		(state: RootState) => state.printLocations.selected
	);

	// TODO VERIFY IS STILL NEEDED
	useEffect(() => {
		if (hasErrors) {
			if (inputFile) if (inputFile?.current) inputFile.current.value = "";
		}
	}, [hasErrors]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (Object.keys(artworkDetailErrors).length > 0) {
			setHasErrors(true);
			if (inputFile) if (inputFile?.current) inputFile.current.value = "";
		}
	}, [artworkDetailErrors]); // eslint-disable-line react-hooks/exhaustive-deps

	// HANDLERS
	const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		setIsOver(true);
	};

	const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		setIsOver(false);
	};

	const handleDrop = (event: DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		setIsOver(false);

		// Fetch the files
		const droppedFiles = Array.from(event.dataTransfer.files);
		return fileReader(droppedFiles);
	};

	const onButtonClick = () => {
		if (!inputFile.current) return;
		inputFile.current.click();
	};

	const onfileUpload = (event: ChangeEvent<HTMLInputElement>) => {
		const files = Array.from(event.target.files as ArrayLike<File>);
		const droppedFiles = Array.from(files);
		return fileReader(droppedFiles);
	};

	const fileReader = (filesToRead: File[]) => {
		if (!selectedItem) return;
		// Use FileReader to read file content
		filesToRead.forEach((file, k) => {
			const reader = new FileReader();

			reader.onloadend = () => {
				const artWork: ArtworkDetail = {
					id: k,
					original_resized: reader.result as string,
					type: selectedPrintType,
					location: selectedPrintLocation?.id,
					position_x: 0,
					position_y: 0,
					scale: 0,
					width_cm: 0,
					height_cm: 0,
				};

				saveDesign(artWork);
			};

			reader.onerror = () => {
				<Alert type={AlertEnum.error}>{t("fileDrop.errorFile")}</Alert>;
			};

			reader.readAsDataURL(file);
			return reader;
		});
	};

	const saveDesign = (artwork: ArtworkDetail) => {
		if (label) {
			// TODO:  SAVE LABEL PRINT
			if (artwork && userDesign) {
				const body = {
					designId: userDesign.id.toString(),
					payload: {
						uploaded_image: artwork.original_resized,
					},
				};
				dispatch(createUserDesignLabelThunk(body));
			}
		} else {
			if (artwork && artwork.type && selectedPrintLocation) {
				const body = {
					type: artwork.type.type,
					uploaded_image: artwork.original_resized,
					location: selectedPrintLocation.placement,
				};
				try {
					dispatch(updateArtworkDetailRequest(body));
				} catch (e: unknown) {
					<Alert type={AlertEnum.error}>{String(e)}</Alert>;
				}
			}
		}
	};

	return (
		<>
			<Wrap $isOver={isOver} onClick={onButtonClick}>
				<div
					className="drop-area"
					onDragOver={handleDragOver}
					onDragLeave={handleDragLeave}
					onDrop={handleDrop}
				>
					<div className="body-sm">{t("fileDrop.description")}</div>
					<input
						id="fileUpload"
						style={{ display: "none" }}
						// TODO : set file extention list in constants
						accept=".png"
						ref={inputFile}
						onChange={(e) => onfileUpload(e)}
						type="file"
					/>
				</div>
			</Wrap>
		</>
	);
};

export default FileDrop;
