import styled from "styled-components";
import { Color } from "../../beans";
import { breakpoints } from "../../constants/breakpoints";

export const Wrap = styled.div<{
	$fabric?: string;
	$selected: boolean;
	$color: Color | undefined;
}>`
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: calc(33% - 10px);
	cursor: pointer;
	pointer-events: all;
	&:has(.fabric-info-button:hover) {
		pointer-events: none;
		.fabric-info-button {
			pointer-events: all;
		}
	}
	&.large {
		.fabric-image {
			height: 72px;
		}
	}
	&.medium {
		.fabric-image {
			height: 32px;
		}
	}
	&.small {
		height: 16px;
		width: 16px;
		.swatch {
			padding: 0;
		}
		.fabric-image {
			height: 16px;
		}
	}
	.swatch {
		border: ${(props) =>
			props.$selected
				? " 1px solid var(--purple100)"
				: " 1px solid var(--gray30)"};

		border-radius: 4px;
		padding: 4px;

		&:hover {
			border: ${(props) =>
				props.$selected
					? "1px solid var(--purple100);"
					: "1px solid var(--gray90);"};
		}

		.fabric-image {
			border-radius: 2px;
			width: 100%;
			max-width: 100%;
			background-color: ${(props) =>
				props.$color ? props.$color.hex : "transparent"};
			background-image: url("${(props) => props.$fabric}");
			background-size: cover;
		}
		.fabric-description {
			margin-top: 8px;
		}
	}
	.fabric-info-button {
		position: absolute;
		top: 4px;
		right: 4px;
	}

	@media only screen and (max-width: ${breakpoints.desktop}px) {
		max-width: 168px;
	}
	@media only screen and (max-width: ${breakpoints.mobile}px) {
		max-width: 50%;
	}
`;
