import React, { useEffect } from "react";
import Header from "../../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";

import { ButtonEnum, ButtonTypeEnum } from "../../beans";
import Card from "../../components/Card";
// import ProductionSummary from "../../components/ProductionSummary";
import PaddingManager from "../../components/PaddingManager";

import { Wrap } from "./styles";
import { routes } from "../../constants/routes";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button";

import Icon from "../../components/Icon";
import {
	emptyOrderPage,
	externalLink,
	phone,
	questionBaloon,
} from "../../constants/icons";
import { getOrderByIdRequest } from "../../store/Order";
import LoadingOptions from "../../components/LoadingOptions";
import OrderItem from "../../components/OrderItem";
import ProductionSummary from "../../components/ProductionSummary";
import {
	getProductionRangeAvailability,
	getProductionRangeThunk,
} from "../../store/ProductionRange";

const OrderCart = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();

	const { t } = useTranslation();

	const { orderId } = useParams();

	// GLOBAL STATE
	const selectedItem = useSelector(
		(state: RootState) => state.userDesign?.data?.item_fabric?.item
	);
	const userDesign = useSelector((state: RootState) => state.userDesign.data);
	const summary = useSelector((state: RootState) => state.order.summary);
	const order = useSelector((state: RootState) => state.order.data);
	const productionRange = useSelector(
		(state: RootState) => state.productionRange.data
	);
	const availableRange = useSelector(
		(state: RootState) => state.productionRange.availableRange
	);

	// HOOKS
	useEffect(() => {
		if (productionRange.length === 0)
			dispatch(getProductionRangeThunk(Number(orderId)));
		if (availableRange.length === 0) dispatch(getProductionRangeAvailability());
	}, [availableRange.length, dispatch, orderId, productionRange.length]);

	useEffect(() => {
		if (!orderId) {
			navigate(routes.home);
		}
		dispatch(getOrderByIdRequest(orderId));
	}, [dispatch, navigate, orderId]);

	useEffect(() => {
		if (order?.id) dispatch(getOrderByIdRequest(order.id));
	}, [order?.id, dispatch]);

	return (
		<>
			<Header
				product
				productName={selectedItem?.name}
				productType={userDesign?.name}
				onClose={() => navigate(routes.home)}
			/>{" "}
			<Wrap>
				<PaddingManager>
					<div className="card-containers">
						<div className="production-options">
							{order?.id ? (
								<>
									{order.orderitem_set.map((orderItem) => (
										<OrderItem
											designId={orderItem.design.id}
											orderId={order?.id}
											orderItem={orderItem}
											key={orderItem.id}
										/>
									))}
								</>
							) : (
								<Card>
									<LoadingOptions />
								</Card>
							)}
							{order?.orderitem_set.length === 0 && (
								<Card>
									<div className="empty-page">
										<Icon icon={emptyOrderPage} />
										<div className="heading-md">{t("production.noDesign")}</div>
										<div className="title-lg">{t("production.orders")}</div>
									</div>
								</Card>
							)}
							<div className="help-container">
								<Card>
									<div>
										<Icon icon={phone} />
									</div>
									<div className="title-sm">
										{t("orderProduction.helpAssistanceTitle")}
									</div>
									<div
										className="label-lg"
										dangerouslySetInnerHTML={{
											__html: t("orderProduction.helpAssistanceDescription"),
										}}
									/>

									<div className="label-lg">
										<Button
											type={ButtonTypeEnum.button}
											style={ButtonEnum.link}
											onClick={() =>
												window.open(
													"https://fabbric.com/pages/solicita-una-llamada-con-un-experto",
													"_blank"
												)
											}
										>
											{t("orderProduction.helpAssistanceButton")}
											<Icon icon={externalLink} />
										</Button>
									</div>
								</Card>
								<Card>
									<div>
										<Icon icon={questionBaloon} />
									</div>
									<div className="title-sm">
										{t("orderProduction.helpAnswerTitle")}
									</div>
									<div className="label-lg">
										{t("orderProduction.helpAnswerDescription")}
									</div>
									<div className="label-lg">
										<Button
											type={ButtonTypeEnum.button}
											style={ButtonEnum.link}
											onClick={() => {
												window.open(
													"https://help-center.fabbric.com/knowledge/fabbric-studio",
													"_blank"
												);
											}}
										>
											{t("orderProduction.helpAnswerButton")}{" "}
											<Icon icon={externalLink} />
										</Button>
									</div>
								</Card>
							</div>
						</div>

						<ProductionSummary summary={summary} orderId={orderId} />
					</div>
				</PaddingManager>
			</Wrap>
		</>
	);
};

export default OrderCart;
